<template>
    <div>
        <div v-if="isLoading">
            <Loading />
        </div>
        <div>
            <ChirpList
                :data_source='rows'
                :export_api='export_api'
                :column_meta='columnMeta'
                @rowSelected='rowSelected'
                @applyCriteria='modifyCriteria'
                :loadingColumns='loadingTable'
                :totalCount='totalCount'
                :storeKey='storeKey'
                :useOverride='useOverride'
                @cancelOverride='useOverride = false'
                :command-columns='commandColumns'
                :hideAction='true'
                @buttonInCellClicked='buttonInCellClicked'
                :filterType="filterType"
                :filterValue="filterValue"
            >

                <template #control='' v-if='canCreateUsers'>
                    <button class='primary'
                            @click="
                            () => {
                                $store.commit('modals/updateField', {
                                    field: 'newUserModal',
                                    value: !$store.state.modals.newUserModal
                                });
                            }
                        "
                    >
                        + New User
                    </button>
                </template>
            </ChirpList>
        </div>
    </div>
    
</template>

<script>
    import ChirpList from '@/components/general/list/ChirpList';
    import { set_prefs, get_prefs } from '@/util/genericUtilityFunctions';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import { mapGetters } from 'vuex';
    // import { users } from '@/util/apiRequests';
    import Loading from '@/components/general/loading/loading';

    export default {
        name: 'Users',
        components: { ChirpList, Loading },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                columnMeta: [
                    { field: 'id', headerText: '', allowFiltering: true },
                    { field: 'firstName', headerText: 'First Name' },
                    { field: 'lastName', headerText: 'Last Name' },
                    { field: 'name', headerText: '', hide:true },
                    { field: 'email', headerText: 'Email', allowFiltering: true, allowSorting: true, width:220 },
                    { field: 'role', headerText: 'Role', allowFiltering: true, allowSorting: true },
                    { field: 'primaryOffice', headerText: 'Primary Office', allowFiltering: true, allowSorting: true },
                    { field: 'status', headerText: 'Status', allowFiltering: true, allowSorting: true },
                    { field: 'npi', hide: 1}
                ],
                loading: true,
                loadingTable: false,
                totalCount: 0,
                useOverride: false,
                export_api: '/users/list',
                commandColumns: [
                    // {
                    //     field: "Commands",
                    //     headerText: "Actions",
                    //     commands: [{
                    //         buttonOption: { content: "Add", cssClass: "e-flat custombutton" },
                    //
                    //     }]
                    // }
                ],
                isLoading: false,
                filterType: null,
                filterValue: null
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'users';
            },
            ...mapGetters(['canCreateUsers'])
        },
        methods: {
            buttonInCellClicked(args){
            },
            rowSelected(args) {
                this.$router.push({ name: 'UserDetail', params: { userId: args.id } });
            },
            async load_data() {
                try {
                    this.isLoading = true;
                    const body = {
                        criteria: this.criteria
                    };
                    const res = await this.$api.post(this.export_api, body);

                    this.isLoading = false;
                    if (res.status >= 400) {
                        this.$toasted.error('Failed to fetch user roster.');
                        return;
                    }

                    this.rows = res.data.rows;

                    this.totalCount = res.data.total_count;
                } catch (err) {
                    this.$toasted.error('Failed to fetch user roster.');
                }
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }

                this.criteriaProps = get_prefs(this.pageMeta.page);

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                this.criteriaProps = criteria;
                set_prefs(this.pageMeta.page, this.criteriaProps);
                this.load_data();
            }
        },
        async created() {
            if( this.$route.query.role ) {
                this.filterType = 'filter';
                this.filterValue = 'Clinician';
            }
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        }
    };
</script>
